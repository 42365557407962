import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToastNotifications } from "../../features/toast-notification/toastNotificationSlice";
import { upsertGroupAsync, clearError, readMyGroupsAsync } from "../../features/group/groupSlice";
import { ReactComponent as IconError } from "../../assets/icons/icon-error.svg";
import { getAdminsAsync } from "../../features/user/userSlice";
import slugify from "slugify";
import Multiselect from "../general/Multiselect";
import NextStepsItemForm from "./NextStepsItemForm";
import { PlusIcon } from "@heroicons/react/outline";
import ImageUploaderFormField from "./utils/ImageUploaderFormField";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Label } from "./utils/Common";
import Button from "../common/Button";

function ReorderableNextStepItems({
  contents,
  handleReorder,
  error,
  setState,
  isDraggingNextStepItem,
  setIsDraggingNextStepItem
}) {
  const onDragEnd = val => {
    if (!val.destination) return;
    const fvsCloned = Array.from(contents);
    const [reordered] = fvsCloned.splice(val.source.index, 1);
    fvsCloned.splice(val.destination.index, 0, reordered);
    handleReorder(fvsCloned);
    setIsDraggingNextStepItem(false);
  };

  return (
    <div>
      {!contents.length ? (
        <p>This group has no next steps items yet.</p>
      ) : (
        <DragDropContext onDragStart={() => setIsDraggingNextStepItem(true)} onDragEnd={onDragEnd}>
          <Droppable
            droppableId="reorder-content"
            renderClone={(provided, snapshot, rubric) => {
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={provided.draggableProps.style}
                  className="border rounded-md border-gray-300 mt-2 py-6 px-4 bg-white"
                >
                  <NextStepsItemForm
                    state={contents[rubric.source.index]}
                    error={error}
                    setState={setState}
                    i={rubric.source.index}
                    isDraggingNextStepItem={isDraggingNextStepItem}
                  />
                </div>
              );
            }}
          >
            {provided => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {contents.map((nextStepItem, idx) => (
                  <Draggable key={idx} draggableId={`draggable-${idx}`} index={idx}>
                    {provided => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={provided.draggableProps.style}
                        className="border rounded-md border-gray-300 mt-2 py-6 px-4 bg-white"
                      >
                        <NextStepsItemForm
                          state={nextStepItem}
                          error={error}
                          setState={setState}
                          i={idx}
                          isDraggingNextStepItem={isDraggingNextStepItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

const GroupForm = ({ model, closeModal }) => {
  const { admins } = useSelector(state => state.user);
  const { currentUser } = useSelector(state => state.auth);
  const isAdmin = currentUser.role === "admin";
  const defaultState = {
    groupCreatorId: currentUser.id,
    adminIds: [currentUser.id],
    GroupNextStepItems: [],
    PlatformId: currentUser.PlatformId
  };
  const [state, setState] = useState(model || defaultState); // form state
  const { flashSuccessNotification, flashErrorNotification } = useToastNotifications();

  const { error } = useSelector(state => state.group);
  const dispatch = useDispatch();

  const onChange = event => {
    const { name, value } = event.target;
    if (error && error[name]) dispatch(clearError({ field: name }));
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async event => {
    event.preventDefault();
    try {
      const action = await dispatch(upsertGroupAsync({ group: { ...state, Users: [] } }));
      if (action.error) return flashErrorNotification();
      await dispatch(readMyGroupsAsync());
      flashSuccessNotification();
      closeModal();
    } catch (error) {
      console.error("Failed to save group", error);
      flashErrorNotification();
    }
  };

  // if the form is opened from the groups page (LeftNavbar)
  // the admins are not preloaded, so we load them manually here
  useEffect(() => {
    // no need to fetch if creator makes group because they're set as the admin
    if (isAdmin) dispatch(getAdminsAsync({ PlatformId: currentUser.PlatformId }));
  }, [dispatch, currentUser.PlatformId, isAdmin]);

  const nextStepItemsContainerRef = useRef(null);

  const [isDraggingNextStepItem, setIsDraggingNextStepItem] = useState(false);

  const handleReorder = newOrder => {
    // UPDATE THE ORDER
    const GroupNextStepItems = newOrder.map((item, i) => ({ ...item, order: i }));
    setState(state => ({ ...state, GroupNextStepItems }));
  };

  return (
    <form onSubmit={event => onSubmit(event)} id="group-form">
      <div className="grid grid-cols-2 gap-8">
        <div>
          <div>
            <Label htmlFor="name">Name</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="name"
                autoFocus
                name="name"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.name) || ""}
                onChange={event => onChange(event)}
                onBlur={event => {
                  if (state && !state.slug) {
                    if (state.name) {
                      setState(prevState => ({
                        ...prevState,
                        slug: slugify(state.name)
                      }));
                    }
                  }
                }}
                placeholder="name"
              />
              {error && error.name && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
            {error && error.name && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>

          <div className="mt-6">
            <Label htmlFor="slug">Slug</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="slug"
                name="slug"
                className={`${
                  error && error.slug
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.slug) || ""}
                onChange={event => onChange(event)}
                placeholder="slug"
              />
              {error && error.slug && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
            {error && error.slug && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>

          <div className="mt-6">
            <Label htmlFor="description">Description</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <textarea
                id="description"
                name="description"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.description) || ""}
                onChange={event => onChange(event)}
                placeholder="description"
              />
              {error && error.description && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="purchaseLink">Purchase link</Label>
            <div className="text-xs leading-4 mt-1 mb-2 text-slate-500">
              Add an link to your sales funnel or landing page. When a user clicks on this group
              they will be redirected to the URL provided. If empty, user will be directed to the
              group.
            </div>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="purchaseLink"
                name="purchaseLink"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.purchaseLink) || ""}
                onChange={event => onChange(event)}
                placeholder="https://purchasehere.com"
              />
              {error && error.purchaseLink && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="externalGroupUrl">Group Redirect URL</Label>
            <div className="text-xs leading-4 mt-1 mb-2 text-slate-500">
              Redirect users here when they are on the group page. Leave this empty unless your
              group is hosted somewhere else.
            </div>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="externalGroupUrl"
                name="externalGroupUrl"
                className={`${
                  error && error.externalGroupUrl
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.externalGroupUrl) || ""}
                onChange={event => onChange(event)}
                placeholder="https://mygroup.com"
              />
              {error && error.externalGroupUrl && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="visibility">Visibility</Label>
            <div className="mt-1 rounded-md shadow-sm">
              <select
                id="visibility"
                className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-gray-300"
                name="visibility"
                value={(state && state.visibility) || ""}
                onChange={event => onChange(event)}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          </div>

          <div className="mt-6">
            <Label htmlFor="position">Position</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="position"
                name="position"
                type="number"
                className={`${
                  error && error.position
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.position) || ""}
                onChange={event => onChange(event)}
                placeholder="position"
              />
              {error && error.position && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
            {error && error.position && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>

          {/* NEXT STEPS ITEMS */}
          <div className="mt-6"></div>
          <Label>Next step items:</Label>

          <div className="max-h-[700px] overflow-auto mb-4" ref={nextStepItemsContainerRef}>
            <ReorderableNextStepItems
              contents={state.GroupNextStepItems || []}
              setState={setState}
              handleReorder={handleReorder}
              error={error}
              isDraggingNextStepItem={isDraggingNextStepItem}
              setIsDraggingNextStepItem={setIsDraggingNextStepItem}
            />
          </div>

          <div className="mt-2">
            <button
              type="button"
              className="w-full inline-flex justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              onClick={() => {
                setTimeout(() => {
                  if (nextStepItemsContainerRef?.current) {
                    // scroll to the bottom of the Next Step Items container after a little bit
                    const nextStepItemHeight = 400;
                    nextStepItemsContainerRef.current.scrollTop =
                      nextStepItemsContainerRef.current.scrollHeight + nextStepItemHeight;
                  }
                }, 500);

                // push a new item
                setState(state => ({
                  ...state,
                  GroupNextStepItems: [
                    ...state.GroupNextStepItems,
                    {
                      order:
                        state.GroupNextStepItems?.length > 0
                          ? Math.max(...state.GroupNextStepItems.map(item => item.order)) + 1
                          : 1
                    }
                  ]
                }));
              }}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Add new item
            </button>
          </div>
          {/* END: NEXT STEPS ITEMS */}
        </div>

        <div>
          <ImageUploaderFormField
            title="Cover Image"
            field="coverImg"
            value={state.coverImg}
            onChange={value => onChange({ target: { name: "coverImg", value } })}
            description={"SVG, PNG, JPG, GIF up to 10MB"}
          />

          {isAdmin && (
            <div className="mt-6">
              <Label htmlFor="adminIds">Group Admins</Label>
              <div className="mt-1 rounded-md shadow-sm relative">
                <Multiselect
                  id="adminIds"
                  name="adminIds"
                  placeholder="Choose admins"
                  onChange={onChange}
                  value={state.adminIds || []}
                  options={admins.map(a => ({ id: a.id, label: a.name }))}
                />
              </div>
              {error && error.adminIds && (
                <p className="mt-2 text-sm text-red-600">This field is required</p>
              )}
            </div>
          )}

          <div className="flex items-start mt-6">
            <div className="flex items-center h-5">
              <input
                id="isTodayTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isTodayTabEnabled"
                checked={state?.isTodayTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isTodayTabEnabled: !state.isTodayTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isTodayTabEnabled" checkbox>
              Today tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isEventsTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isEventsTabEnabled"
                checked={state?.isEventsTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isEventsTabEnabled: !state.isEventsTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isEventsTabEnabled" checkbox>
              Events tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isReplayTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isReplayTabEnabled"
                checked={state?.isReplayTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isReplayTabEnabled: !state.isReplayTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isReplayTabEnabled" checkbox>
              Replay tab
            </Label>
          </div>

          {/* Courses in BETA */}
          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isCoursesTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isCoursesTabEnabled"
                checked={state?.isCoursesTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isCoursesTabEnabled: !state.isCoursesTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isCoursesTabEnabled" checkbox>
              Courses (BETA)
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isChatbotTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isChatbotTabEnabled"
                checked={state?.isChatbotTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isChatbotTabEnabled: !state.isChatbotTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isChatbotTabEnabled" checkbox>
              Chatbot tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isCommunityTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isCommunityTabEnabled"
                checked={state?.isCommunityTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isCommunityTabEnabled: !state.isCommunityTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isCommunityTabEnabled" checkbox>
              Community tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isCommunityAdminOnlyTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isCommunityAdminOnlyTabEnabled"
                checked={state?.isCommunityAdminOnlyTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isCommunityAdminOnlyTabEnabled: !state.isCommunityAdminOnlyTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isCommunityAdminOnlyTabEnabled" checkbox>
              Announcements tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isMembersTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isMembersTabEnabled"
                checked={state?.isMembersTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isMembersTabEnabled: !state.isMembersTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isMembersTabEnabled" checkbox>
              Members tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isTrainingTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isTrainingTabEnabled"
                checked={state?.isTrainingTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isTrainingTabEnabled: !state.isTrainingTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isTrainingTabEnabled" checkbox>
              Training tab
            </Label>
          </div>

          <div className="flex items-start mt-2">
            <div className="flex items-center h-5">
              <input
                id="isNextStepsTabEnabled"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border"
                name="isNextStepsTabEnabled"
                checked={state?.isNextStepsTabEnabled || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    isNextStepsTabEnabled: !state.isNextStepsTabEnabled
                  }));
                }}
              />
            </div>
            <Label htmlFor="isNextStepsTabEnabled" checkbox>
              Next Steps tab
            </Label>
          </div>

          {/* Extra inputs */}

          <div className="mt-6">
            <Label htmlFor="headlineText">Headline</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="headlineText"
                name="headlineText"
                className={`${
                  error && error.headlineText
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.headlineText) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.headlineText && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>

          <div className="mt-6">
            <Label htmlFor="headlineBtnText">CTA Button Text</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="headlineBtnText"
                name="headlineBtnText"
                className={`${
                  error && error.headlineBtnText
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.headlineBtnText) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.headlineBtnText && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="headlineBtnUrl">CTA Button URL</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="headlineBtnUrl"
                name="headlineBtnUrl"
                className={`${
                  error && error.headlineBtnUrl
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.headlineBtnUrl) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.headlineBtnUrl && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>

          <div className="mt-6">
            <Label htmlFor="sessionHeadlineText">Subheadline</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="sessionHeadlineText"
                name="sessionHeadlineText"
                className={`${
                  error && error.sessionHeadlineText
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.sessionHeadlineText) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.sessionHeadlineText && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="eventUrl">Event URL</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="eventUrl"
                name="eventUrl"
                className={`${
                  error && error.eventUrl
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.eventUrl) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.eventUrl && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="chatbotEmbedUrl">Chatbot Embed Url</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="chatbotEmbedUrl"
                name="chatbotEmbedUrl"
                className={`${
                  error && error.chatbotEmbedUrl
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.chatbotEmbedUrl) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.chatbotEmbedUrl && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label htmlFor="chatbotTabLabel">Chatbot Tab Label</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="chatbotTabLabel"
                name="chatbotTabLabel"
                className={`${
                  error && error.chatbotTabLabel
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.chatbotTabLabel) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.chatbotTabLabel && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <IconError />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-3">
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <Button type="submit" variant="primary">
          Save
        </Button>
      </div>
    </form>
  );
};

export default GroupForm;

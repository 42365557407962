import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import { uploadFile } from "../../../features/uploader/upload";
import { useDropzone } from "react-dropzone";
import { getImageSrcRelOrAbsolute } from "../../../utils";
import { Label } from "./Common";

const ImageUploaderFormField = ({
  title,
  field,
  id,
  instructions,
  value,
  onChange,
  description
}) => {
  const file = useRef(null);
  const [loading, setLoading] = useState(false);

  const onDropAccepted = useCallback(async acceptedFiles => {
    file.current.value = null;
    const chosenFile = acceptedFiles[0];
    if (!chosenFile) return;

    setLoading(true);
    const uploadedFileUrl = await uploadFile({ file: chosenFile });
    setLoading(false);
    onChange(uploadedFileUrl);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    accept: {
      "image/*": []
    },
    maxFiles: 1,
    maxSize: 10000000
  });

  const deleteFileFromModel = () => {
    onChange(null);
  };

  // upload file listener
  useEffect(() => {
    file.current.onclick = () => {
      file.current.value = null;
    };

    return () => {
      //dispatch(deleteFile());
      //deleteFileFromModel();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="">
      <Label htmlFor={id || field}>{title}</Label>

      {instructions !== null ? (
        <div className="text-xs leading-4 mt-1 mb-2 text-gray-600">{instructions}</div>
      ) : null}
      <input
        {...getInputProps()}
        id={id || field}
        type="file"
        name={field}
        ref={file}
        accept="image/*"
        multiple={false}
      />
      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 bg-white border-2 border-gray-300 border-dashed rounded-md">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {value ? (
              <div>
                <img src={getImageSrcRelOrAbsolute(value, "?tr=h-288")} alt="preview" />

                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-red-300 text-xs leading-4 font-medium rounded text-red-300 bg-white hover:text-red-200 focus:outline-none focus:border-red-200 focus:shadow-outline-redd active:text-red-200 active:bg-gray-50 transition ease-in-out duration-150"
                    onClick={deleteFileFromModel}
                  >
                    Delete {title}
                  </button>
                </span>
              </div>
            ) : (
              <div
                {...getRootProps()}
                onClick={() => {
                  return;
                }}
                className="text-center"
              >
                <AddImageIcon />
                <p className="mt-1 text-sm text-gray-600">
                  <button
                    type="button"
                    className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                    onClick={() => {
                      if (file && file.current) {
                        file.current.click();
                      }
                    }}
                  >
                    {isDragActive ? "Drop the files here ..." : "Upload a file"}
                  </button>
                </p>
                <p className="mt-1 text-xs text-slate-500">{description}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const AddImageIcon = () => (
  <svg
    className="mx-auto h-12 w-12 text-gray-400"
    stroke="currentColor"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImageUploaderFormField;

export function Label({ htmlFor, label, children, checkbox }) {
  const classes = checkbox ? checkboxLabelClasses : inputLabelClasses;

  return (
    <label htmlFor={htmlFor} className={classes}>
      {label || children}
    </label>
  );
}

const inputLabelClasses = "block mb-1 text-sm font-medium leading-5 text-slate-700";
const checkboxLabelClasses = "ml-3 text-sm leading-5 font-medium text-slate-700";

import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// redux state

const initialState = {
  showing: false,
  icon: null,
  type: null,
  title: null,
  details: null
};
export const toastNotificationSlice = createSlice({
  name: "toastNotification",
  initialState,
  reducers: {
    setToastNotificationShowing: (state, action) => {
      state.showing = action.payload;
    },
    setToastNotificationState: (state, action) => {
      for (const prop in action.payload) {
        state[prop] = action.payload[prop];
      }
    },
    clearToastNotification: state => {
      state.showing = false;
    }
  }
});

export const { setToastNotificationShowing, setToastNotificationState, clearToastNotification } =
  toastNotificationSlice.actions;

export const flashToastNotification = () => dispatch => dispatch(setToastNotificationShowing(true));

export const flashDetailedToastNotification = payload => dispatch =>
  dispatch(setToastNotificationState({ ...initialState, showing: true, ...payload }));

// hooks

export const useToastNotifications = () => {
  const dispatch = useDispatch();

  function flashErrorNotification(notif) {
    const args = { ...initialState, showing: true, type: "error" };

    if (typeof notif === "string") args.details = notif;
    else if (typeof notif === "object") {
      args.title = notif.title;
      args.details = notif.details;
    }

    dispatch(setToastNotificationState(args));
  }

  function flashSuccessNotification(notif) {
    const args = { ...initialState, showing: true };

    if (typeof notif === "string") args.details = notif;
    else if (typeof notif === "object") {
      args.title = notif.title;
      args.details = notif.details;
    }

    dispatch(setToastNotificationState(args));
  }

  return { flashErrorNotification, flashSuccessNotification };
};

export default toastNotificationSlice.reducer;

import React from "react";
import { ReactComponent as IconError } from "../../assets/icons/icon-error.svg";
import ImageUploaderFormField from "./utils/ImageUploaderFormField";
import { XCircleIcon } from "@heroicons/react/outline";
import { Label } from "./utils/Common";

const NextStepsItemForm = ({ state, error, setState, i }) => {
  function onChange(event) {
    setState(state => ({
      ...state,
      GroupNextStepItems: state.GroupNextStepItems?.map((nextStepItem, idx) => {
        if (i === idx) {
          return { ...nextStepItem, [event.target.name]: event.target.value };
        }
        return nextStepItem;
      })
    }));
  }

  return (
    <div className="flex relative">
      {/* NEXT STEPS ITEM PHOTO */}
      <div className="flex-1">
        {/* REMOVE NEXT STEPS ITEM BUTTON */}
        <button
          type="button"
          className="absolute top-[-25px] left-[-15px] inline-flex justify-center text-sm text-slate-700 hover:text-slate-500 focus:outline-none active:text-gray-800 transition duration-150 ease-in-out"
          onClick={() =>
            setState(state => ({
              ...state,
              GroupNextStepItems: state.GroupNextStepItems?.filter((_, idx) => i !== idx)
            }))
          }
        >
          <XCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        </button>

        <ImageUploaderFormField
          title="Photo"
          field={`photoURL`}
          value={state.photoURL}
          onChange={value => onChange({ target: { name: "photoURL", value } })}
          description={"1280x720 or 1920x1080px SVG, PNG, JPG, GIF up to 10MB"}
        />

        {/* NEXT STEPS ITEM FEATURED? */}
        <div className="flex items-start mt-4">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              id="featured"
              name="featured"
              className={`${
                error && error.featured
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out border`}
              checked={state?.featured || false}
              onChange={event =>
                onChange({
                  target: { name: event.target.name, value: event.target.checked || false }
                })
              }
            />
            {error && error.featured && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <IconError />
              </div>
            )}
          </div>
          <Label htmlFor="featured" checkbox>
            Featured?
          </Label>
        </div>
      </div>

      <div className="flex-1 px-2">
        {/* NEXT STEPS ITEM TITLE */}
        <div>
          <Label htmlFor="title">Title</Label>
          <div className="mt-1 rounded-md shadow-sm relative">
            <input
              id="title"
              name="title"
              className={`${
                error && error.title
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={(state && state.title) || ""}
              onChange={event => onChange(event)}
              placeholder="Title"
            />
            {error && error.title && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <IconError />
              </div>
            )}
          </div>
          {error && error.title && (
            <p className="mt-2 text-sm text-red-600">This field is required</p>
          )}
        </div>

        {/* NEXT STEPS ITEM BUTTON CTA TEXT */}
        <div className="mt-6">
          <Label htmlFor="CTAButtonText">CTA Button text</Label>
          <div className="mt-1 rounded-md shadow-sm relative">
            <input
              id="CTAButtonText"
              name="CTAButtonText"
              className={`${
                error && error.CTAButtonText
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={state?.CTAButtonText || ""}
              onChange={event => onChange(event)}
              placeholder="CTA Button Text..."
            />
            {error && error.CTAButtonText && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <IconError />
              </div>
            )}
          </div>
        </div>

        {/* NEXT STEPS ITEM URL */}
        <div className="mt-6">
          <Label htmlFor="url">Destination URL</Label>
          <div className="mt-1 rounded-md shadow-sm relative">
            <input
              id="url"
              name="url"
              className={`${
                error && error.url
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={(state && state.url) || ""}
              onChange={event => onChange(event)}
              placeholder="URL"
              required
            />
            {error && error.url && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <IconError />
              </div>
            )}
          </div>
          {error && error.url && (
            <p className="mt-2 text-sm text-red-600">This field is required</p>
          )}
        </div>

        {/* NEXT STEPS ITEM URL */}
        <div className="mt-6">
          <Label htmlFor="description">Description</Label>
          <div className="mt-1 rounded-md shadow-sm relative">
            <textarea
              id="description"
              name="description"
              className={`${
                error && error.description
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={state?.description || ""}
              onChange={event => onChange(event)}
              placeholder="Description"
            />
            {error && error.description && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <IconError />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextStepsItemForm;
